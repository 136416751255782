import FormModal from "@/layouts/FormModal";
import { FormLabel } from "@/layouts/Label";
import { FoodSupplier } from "@/models/foodSupplier";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface AddEditFoodSupplierProps {
  onSave?: (formData: FoodSupplier) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading?: boolean;
  foodSupplier?: FoodSupplier; // Data for pre-filling the form in edit mode
  isEdit?: boolean; // Flag to indicate data loading for edit mode
}

const AddEditFoodSupplier: React.FC<AddEditFoodSupplierProps> = ({
  isOpen,
  onClose,
  title,
  foodSupplier,
  isEdit,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    //formState: { /*errors*/ }, //commented to commit
  } = useForm<FoodSupplier>();

  useEffect(() => {
    if (isOpen) {
      reset({
        name: "",
        address: "",
        contactPersonName: "",
        contactPersonMobileNo: "",
        breakfastRate: "",
        lunchRate: "",
        dinnerRate: "",
        bankName: "",
        bankBranch: "",
        accNo: "",
      });
    }

    if (foodSupplier && isEdit) {
      reset({
        name: foodSupplier.name,
        address: foodSupplier.address,
        contactPersonName: foodSupplier.contactPersonName,
        contactPersonMobileNo: foodSupplier.contactPersonMobileNo,
        breakfastRate: foodSupplier.breakfastRate,
        lunchRate: foodSupplier.lunchRate,
        dinnerRate: foodSupplier.dinnerRate,
        bankName: foodSupplier.bankName,
        bankBranch: foodSupplier.bankBranch,
        accNo: foodSupplier.accNo,
      });
    } else {
      reset();
    }
  }, [foodSupplier, reset, isOpen, isEdit]);

  // Get the query client instance
  const queryClient = useQueryClient();

  // Adding a food supplier
  const mutation = useMutation({
    mutationFn: async (foodSupplier) => {
      const response = await axiosInstance.post("/foodSuppliers", foodSupplier);
      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of food suppliers after adding a new food supplier
      await queryClient.invalidateQueries("foodSuppliers" as any);
      toast.success("Food Supplier Details Added Successfully");
    },
    onError: async () => {
      toast.error("Could not add food supplier details, Please try again");
    },
  });

  const updateMutation = useMutation({
    mutationFn: ({ supplierId, foodSupplier }: any) => {
      return axiosInstance.patch(`/foodSuppliers/${supplierId}`, foodSupplier);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("foodSuppliers" as any);
      toast.success("Food Supplier Details Updated Successfully");
    },
    onError: async () => {
      toast.error("Could not update food supplier details, Please try again");
    },
  });

  const onSubmit: SubmitHandler<FoodSupplier> = async (formData) => {
    // Perform add and edit based on mode
    if (isEdit && foodSupplier) {
      await updateMutation.mutateAsync({
        supplierId: foodSupplier.id!,
        foodSupplier: formData,
      });
    } else {
      await mutation.mutateAsync(formData as any);
    }
    reset();
    onClose();
  };

  return (
    <FormModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      onSave={handleSubmit(onSubmit)}
      isLoading={mutation.isPending || updateMutation.isPending}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {/** {isDataLoading && (
          <div>
            <SpinnerOverlay />
          </div>
        )}*/}
        <div className="flex flex-col space-y-10">
          <div className=" text-lg font-semibold text-primary-text">
            Supplier Details
          </div>
          <div>
            <FormLabel label="Supplier Name"  required/>
            <InputText
              {...register("name", {
                required: "This field is required.",
              })}
              className="!bg-inputBg"
              // placeholder="SO Name"
            />
          </div>
          <div>
            <FormLabel label="Address" required/>
            <InputTextarea
              //placeholder="Address"
              color="blue"
              className=" !bg-inputBg !w-full"
              {...register("address", {
                required: "This field is required.",
              })}
            />
          </div>
          <div className="flex flex-row space-x-4">
            <div>
              <FormLabel label="Contact Person Name" required/>
              <InputText
                {...register("contactPersonName", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[600px]"
                // placeholder="SO Name"
              />
            </div>
            <div>
              <FormLabel label="Contact Person Telephone Number" required/>
              <InputText
                {...register("contactPersonMobileNo", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[270px] "
                // placeholder="SO Name"
              />
            </div>
          </div>
          <div className="flex flex-row space-x-10">
            <div>
              <FormLabel label="Breakfast Rate" required/>
              <InputText
                {...register("breakfastRate", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[270px]"
                // placeholder="SO Name"
              />
            </div>
            <div>
              <FormLabel label="Lunch Rate" required />
              <InputText
                {...register("lunchRate", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[270px]"
                // placeholder="SO Name"
              />
            </div>
            <div>
              <FormLabel label="Dinner Rate" required />
              <InputText
                {...register("dinnerRate", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[270px]"
                // placeholder="SO Name"
              />
            </div>
          </div>
          <div className=" text-lg font-semibold text-primary-text">
            Bank Details
          </div>
          <div className="flex flex-row space-x-10">
            <div>
              <FormLabel label="Bank Name"  required/>
              <InputText
                {...register("bankName", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[270px]"
                // placeholder="SO Name"
              />
            </div>
            <div>
              <FormLabel label="Bank Branch" required/>
              <InputText
                {...register("bankBranch", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[270px]"
                // placeholder="SO Name"
              />
            </div>
            <div>
              <FormLabel label="Bank Account Number" required/>
              <InputText
                {...register("accNo", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[270px]"
                // placeholder="SO Name"
              />
            </div>
          </div>
        </div>
      </form>
    </FormModal>
  );
};

export default AddEditFoodSupplier;
