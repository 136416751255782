import { Levels, Months } from "@/constants/common";
import FormModal from "@/layouts/FormModal";
import { FormLabel } from "@/layouts/Label";
import { Shift } from "@/models/shift";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface AddEditShiftProps {
  onSave?: (formData: Shift) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading?: boolean;
  shift?: Shift; // Data for pre-filling the form in edit mode
  isEdit?: boolean; // Flag to indicate data loading for edit mode
  shifts: Shift[];
  customerId: string | null;
  setRosters : any
}

const AddEditShift: React.FC<AddEditShiftProps> = ({
  isOpen,
  onClose,
  title,
  shift,
  customerId,
  setRosters,
  shifts,
}) => {
  const { register, handleSubmit, reset, control } = useForm<Shift>();

  const onSubmit: SubmitHandler<Shift> = async (formData) => {
    mutation.mutate(formData as any);

    reset();
    onClose();
  };

  const tableHeaders = ["Company Name", "Shifts"];
  const levelOptions = Levels;

  useEffect(() => {}, [shifts]);

  // Fetch customers by ID (only if selectedCustomerId is not null)
  const { data: selectedCustomer } = useQuery({
    queryKey: ["customers", customerId],
    queryFn: async () => {
      if (customerId) {
        const response = await axiosInstance.get(`/customers/${customerId}`);
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      }
      return null;
    },
    enabled: !!customerId, // Only fetch data if selectedCustomerId is truthy
  });

  const officers = shifts?.map((officer) => ({
    value: officer.officerId,
    label: `${officer.refNo} - ${officer.name}`,
  }));

  const officerOptionTemplate = (option) => (
    <div className="align-items-center flex">
      <div>{option.label}</div>
    </div>
  );

  const officerSelectedTemplate = (option) => {
    if (option) {
      return (
        <div className="align-items-center flex">
          <div>{option.label}</div>
        </div>
      );
    }
    return null; // Return null if option is null or undefined
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        location: selectedCustomer?.name,
        officerId: "",
      });
      setSelectedOfficerId(null);
    }
  }, [shift, reset, isOpen, selectedCustomer]);

  const [selectedOfficerId, setSelectedOfficerId] = useState<string | null>(
    null,
  );

  const date = new Date();

  //get days In Month
  const daysInMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  ).getDate();

  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const monthString = Months[monthIndex];

  const lowerCasedMonth = monthString.toLocaleLowerCase();

  //getting shift summary for the selected officer
  const { data: selectedOfficerSummary } = useQuery({
    queryKey: ["Officers", selectedOfficerId, year, lowerCasedMonth],
    queryFn: async () => {
      if (selectedOfficerId) {
        const response = await axiosInstance.get(
          `/shiftSummary/${selectedOfficerId}/${year}/${lowerCasedMonth}`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      }
      return null;
    },
    enabled: !!selectedOfficerId, // Only fetch data if selectedOfficerId is truthy
  });

  const handleRankChange = (e: DropdownChangeEvent) => {
    const { value } = e;
    setSelectedOfficerId(value); // Set the selected officer ID
  };

  // Get the query client instance
  const queryClient = useQueryClient();

  //Adding a shift
  const mutation = useMutation({
    mutationFn: async (roster) => {
      const response = await axiosInstance.post(
        `/customer/${customerId}/so/${selectedOfficerId}/${monthString}/${year}`,
        roster,
      );
      return response.data;
    },
    onSuccess: async (updatedRoster : any) => {
      setRosters((prevRosters) => {
        const index = prevRosters.findIndex(
          (roster) =>
            roster.customerId === updatedRoster.customerId 
        );
  
        if (index !== -1) {
          // Replace the existing object with the updated one
          const updatedRosters = [...prevRosters];
          updatedRosters[index] = updatedRoster;
          return updatedRosters;
        } else {
          // Add the new roster object if it doesn't already exist in the list
          return [...prevRosters, updatedRoster];
        }
      });
      await queryClient.invalidateQueries("DailyRosters" as any);
      toast.success("Security Officer Allocated Successfully");
    },
    onError: async () => {
      toast.error(
        "Could not allocate security officer to customer, Please try again",
      );
    },
  });

  return (
    <FormModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      onSave={handleSubmit(onSubmit)}
      isLoading={mutation.isPending}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {/** {isDataLoading && (
          <div>
            <SpinnerOverlay />
          </div>
        )}*/}
        <div className="flex flex-col space-y-10">
          <div>
            <FormLabel label="Location" required/>
            <InputText
              {...register("location", {
                required: "This field is required.",
              })}
              className="!bg-inputBg"
              defaultValue={selectedCustomer?.name as any}
              readOnly
            />
          </div>
          <div>
            <FormLabel label="Officer Name" required />
            <Dropdown
              value={selectedOfficerId}
              onChange={handleRankChange}
              options={officers}
              // optionLabel="name"
              placeholder="Select a Security Officer"
              filter
              valueTemplate={officerSelectedTemplate}
              itemTemplate={officerOptionTemplate}
              className="!h-12 w-full"
            />
          </div>
          <div>
            <FormLabel label="Customer Rank" required/>
            <Controller
              name={`cusRole`}
              control={control}
              rules={{ required: "This field is required." }}
              render={({ field }) => (
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e: DropdownChangeEvent) => field.onChange(e.value)}
                  options={levelOptions}
                  placeholder="Select rank"
                  className=" w-full"
                />
              )}
            />
          </div>
          <div className="grid grid-cols-1  gap-4 lg:grid-cols-2 lg:gap-8">
            <div>
              <div className="flex flex-col items-start space-y-2">
                <div className=" text-lg font-semibold text-primary-text">
                  Remaining Shifts
                </div>
                <div className="text-lg font-normal text-primary-blueLigter">
                  Day -{" "}
                  {selectedOfficerSummary
                    ? selectedOfficerSummary.remainingDayShifts
                    : 0}
                </div>
                <div className="text-lg font-normal text-primary-blueLigter">
                  Night -{" "}
                  {selectedOfficerSummary
                    ? selectedOfficerSummary.remainingNightShifts
                    : 0}
                </div>
              </div>
            </div>
            <div></div>
          </div>

          <table className=" mt-3">
            <thead>
              <tr>
                {tableHeaders.map((head) => (
                  <th
                    key={head}
                    className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-start text-lg font-semibold text-primary-text"
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {selectedOfficerSummary && selectedOfficerSummary.customerDetails
                ? selectedOfficerSummary.customerDetails.map((customer) => (
                    <tr key={customer.customerName}>
                      <td className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-sm font-normal text-primary-text">
                        {customer.customerName}
                      </td>
                      <td className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-sm font-normal text-primary-text">
                        <span className="font-semibold text-primary-blueLigter">
                          {customer.totalShifts} Shifts
                        </span>
                        <div className="flex flex-row items-center">
                          <span className="mr-1 text-sm font-normal text-primary-blueLigter">
                            D
                          </span>
                          <span className="text-sm font-normal text-primary-blueLigter">
                            {customer.totalDayShifts}
                          </span>
                          <span className="text-sm font-normal text-primary-blueLigter">
                            /
                          </span>
                          <span className="text-sm font-normal text-primary-blueLigter">
                            {daysInMonth}
                          </span>
                        </div>
                        <div className="flex flex-row items-center">
                          <span className="mr-1 text-sm font-normal text-primary-blueLigter">
                            N
                          </span>
                          <span className="text-sm font-normal text-primary-blueLigter">
                            {customer.totalNightShifts}
                          </span>
                          <span className="text-sm font-normal text-primary-blueLigter">
                            /
                          </span>
                          <span className="text-sm font-normal text-primary-blueLigter">
                            {daysInMonth}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </form>
    </FormModal>
  );
};

export default AddEditShift;
