import FormModal from "@/layouts/FormModal";
import { FormLabel } from "@/layouts/Label";
import { SecurityPerson } from "@/models/securityPerson";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useRef, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import PhotoModal from "./PhotoModal";
//import { FileUpload } from "primereact/fileupload";
import { Levels } from "@/constants/common";
import axiosInstance from "@/utils/apiUtil";
import {
  deleteFileFromS3,
  downloadFileFromS3,
  uploadFileToS3,
} from "@/utils/commonUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Calendar } from "primereact/calendar";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { FileUpload } from "primereact/fileupload";
import {
  InputNumber,
  InputNumberValueChangeEvent,
} from "primereact/inputnumber";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import { toast } from "react-toastify";

interface ddEditSecurityPersonProps {
  onSave?: (formData: SecurityPerson) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading?: boolean;
  securityPerson?: SecurityPerson; // Data for pre-filling the form in edit mode
  isEdit?: boolean; // Flag to indicate data loading for edit mode
}

const tableHeaders = [
  "Item Description",
  "Item Varient",
  "Qty",
  "Unit Price",
  "Total Price",
  "Action",
];

const AddEditSecurityPerson: React.FC<ddEditSecurityPersonProps> = ({
  isOpen,
  onClose,
  title,
  securityPerson,
  isEdit,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    //formState: { /*errors*/ }, //commented to commit
    control,
  } = useForm<SecurityPerson>();

  const toastRef = useRef(null);

  const [editableRows, setEditableRows] = useState({});
  const [isOpenPhotoModal, setisPhotoAddModal] = useState(false);
  const [isEPFEnabled, setIsEPFEnabled] = useState(false);
  const [capturedScreenshot, setCapturedScreenshot] = useState<string | null>();
  const [profilePhoto, setProfilePhoto] = useState<string | null>();
  const [nicFile, setNICFile] = useState<File | null>();
  const [nicFileB, setNICFileB] = useState<File | null>();
  const [gsnFile, setGSNFile] = useState<File | null>();

  const [bcFile, setBCFile] = useState<File | null>();
  const [bc2File, setBC2File] = useState<File | null>();
  const [birthCFrFile, setBirthCFrFile] = useState<File | null>();
  const [birthCFile, setBirthCFile] = useState<File | null>();

  const [otherDocs, setOtherDocs] = useState<File[] | null>();

  const nicFileInputRef = useRef(null);
  const nicFileBInputRef = useRef(null);
  const gsnFileInputRef = useRef(null);
  // const bcFileInputRef = useRef(null);
  // const bc2FileInputRef = useRef(null);
  const birthCFileInputRef = useRef(null);
  const otherDocsRef = useRef(null);
  const birthCFrFileInputRef = useRef(null);

  const [nicFileUrl, setNICFileUrl] = useState<string | null>("");
  const [nicFileBUrl, setNICFileBUrl] = useState<string | null>("");
  const [, setBCFileUrl] = useState<string | null>(""); //bcFileUrl
  const [, setBC2FileUrl] = useState<string | null>(""); //bc2FileUrl
  const [birthCFrUrl, setBirthCFrUrl] = useState<string | null>("");
  const [birthCFileUrl, setBirthCFileUrl] = useState<string | null>("");
  const [gsnFileUrl, setGSNFileUrl] = useState<string | null>("");
  const [filePathsMap, setFilePathsMap] = useState({});
  const [deleteFileInProgress, setDeleteFileInProgress] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedVarient, setSelectedVarient] = useState("");
  const [quantity, setQuantity] = useState(1);

  const interviewStatus = watch("interviewStatus");
  const activeStatus = watch("activeStatus");

  /* const [uploading, setUploading] = useState(false);

  const handleNicUpload = async (file) => {

    setUploading(true);
 try {
      await uploadFileToS3(file);

      setNICFile,
      setNICFileUrl,

    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setUploading(false);
    }
  };

  const triggerFileInputClick = (inputRef) => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };*/

  //grtting Inventory List
  const { data: Items } = useQuery({
    queryKey: ["InventoryItems"],
    queryFn: async () => {
      const response = await axiosInstance.get("/inventory");
      if (!response) {
        throw new Error("Network response was not ok");
      }

      return response.data;
    },
  });

  const items = Items?.map((item) => ({
    label: item.name,
    value: { itemId: item.itemId, itemName: item.name }, //change to label
  }));

  // const varients = [{ name: "Large", value: "large"}, {name: "Medium", value: "medium"}, {name:"Small", value:"small"}]
  // const options = varients.map((varient) => ({
  //   label : varient.name,
  //   value : varient.value,
  // }))

  const [hasFileChanged, setHasFileChanged] = useState({
    nic: false,
    nicB: false,
    birthCertificateBack: false,
    bankCopy: false,
    bankCopy2: false,
    birthCertificateFront: false,
    gramaNiladhariDiv: false,
  });

  useEffect(() => {
    if (isOpen) {
      if (!isEdit) {
        resetState();
        reset({
          profilePic: "",
          name: "",
          address: "",
          mobileNo: "",
          appliedLevel: "",
          officerLevel: "",
          nic: {
            nicValue: "",
            issuedDate: "",
          },
          nicBack: {
            nicValue: "",
            // issuedDate: "",
          },
          gramaNiladhariDiv: {
            val: "",
          },
          birthCertificateBack: "",
          birthCertificateFront: "",
          bankName: "",
          bankBranch: "",
          accNo: undefined,
          bankName2: "",
          bankBranch2: "",
          accNo2: undefined,
          interviewStatus: "PENDING",
          currentStatus: "At Work",
          activeStatus: false,
          inactivatedDate: activeStatus === false ? new Date() : undefined,
          inactivatedReason: "",
          joinedDate : undefined,
          bloodGroup: "",
          illnessDetails: "",
          isEPF: false,
          EPFnumber: "",
          basicSalary: 0,
          dayShiftPayment: 0,
          nightShiftPayment: 0,
          inventoryItems: [],
        });
      }

      if (securityPerson && isEdit) {
        if (securityPerson?.nic?.s3Path) {
          setFileDataFromS3(
            securityPerson?.nic?.s3Path,
            setNICFile,
            setNICFileUrl,
          );
        } else {
          resetNICFile();
        }

        if (securityPerson?.nicBack?.s3Path) {
          setFileDataFromS3(
            securityPerson?.nicBack?.s3Path,
            setNICFileB,
            setNICFileBUrl,
          );
        } else {
          resetNICBFile();
        }

        if (securityPerson?.gramaNiladhariDiv?.s3Path) {
          setFileDataFromS3(
            securityPerson?.gramaNiladhariDiv?.s3Path,
            setGSNFile,
            setGSNFileUrl,
          );
        } else {
          resetGSNFile();
        }

        if (securityPerson?.bankCopy?.s3Path) {
          setFileDataFromS3(
            securityPerson?.bankCopy?.s3Path,
            setBCFile,
            setBCFileUrl,
          );
        } else {
          resetBCFile();
        }

        if (securityPerson?.bankCopy2?.s3Path) {
          setFileDataFromS3(
            securityPerson?.bankCopy2?.s3Path,
            setBC2File,
            setBC2FileUrl,
          );
        } else {
          resetBC2File();
        }

        if (securityPerson?.birthCertificateBack?.s3Path) {
          setFileDataFromS3(
            securityPerson?.birthCertificateBack?.s3Path,
            setBirthCFile,
            setBirthCFileUrl,
          );
        } else {
          resetBirthCFile();
        }

        if (securityPerson?.birthCertificateFront?.s3Path) {
          setFileDataFromS3(
            securityPerson?.birthCertificateFront?.s3Path,
            setBirthCFrFile,
            setBirthCFrUrl,
          );
        } else {
          resetBirthCertificateFrFile();
        }

        if (securityPerson?.profilePic) {
          setFileDataFromS3(
            securityPerson?.profilePic,
            undefined,
            setCapturedScreenshot,
          );
        } else {
          setCapturedScreenshot(null);
        }

        if (
          securityPerson?.otherDocuments &&
          securityPerson?.otherDocuments?.length > 0
        ) {
          setFilesDataFromS3(securityPerson?.otherDocuments, (files) => {
            updateFiles(files);
          });
        }
        //setting the state of isEPFEnabled
        setIsEPFEnabled(securityPerson.isEPF);

        reset({
          ...securityPerson,
          dob: new Date(securityPerson.dob || ""),
          nic: {
            nicValue: securityPerson.nic?.nicValue,
            issuedDate: new Date(securityPerson.nic?.issuedDate || ""),
          },
          nicBack: {
            nicValue: securityPerson.nicBack?.nicValue,
            // issuedDate: new Date(securityPerson.nic.issuedDate || ""),
          },
          birthCertificateFront: "",
          birthCertificateBack: "",
          bankCopy: "",
          bankCopy2: "",
          currentStatus: securityPerson.currentStatus,
          inactivatedReason: securityPerson.inactivatedReason
            ? securityPerson.inactivatedReason
            : "",
          inactivatedDate : securityPerson.inactivatedDate ? new Date(securityPerson.inactivatedDate) : new Date(),
          joinedDate : securityPerson.joinedDate ? new Date(securityPerson.joinedDate) : "",
          isEPF: securityPerson.isEPF,
          EPFnumber: securityPerson.EPFnumber,
          basicSalary: securityPerson.basicSalary,
          dayShiftPayment: securityPerson.dayShiftPayment,
          nightShiftPayment: securityPerson.nightShiftPayment,
          inventoryItems: securityPerson.inventoryItems?.map((item: any) => {
            return {
              ...item,
              itemName: { itemName: item.itemName, itemId: item.itemId },
            };
          }),
          appliedLevel: Levels.find(
            (level) => level.value === securityPerson.appliedLevel,
          )?.label,
          officerLevel: Levels.find(
            (level) => level.value === securityPerson.officerLevel,
          )?.label,
        });
      } else {
        reset();
      }
    }
  }, [securityPerson, reset, isOpen, isEdit]); //remove the active status dependency when running and building

  // Get the query client instance
  const queryClient = useQueryClient();

  // Adding security officer
  const resetState = () => {
    setCapturedScreenshot(null);
    setNICFile(null);
    setNICFileB(null);
    setBCFile(null);
    setBC2File(null);
    setBirthCFrFile(null);
    setBirthCFile(null);
    setGSNFile(null);
    setDeleteFileInProgress(false);

    setNICFileUrl(null);
    setNICFileBUrl(null);
    setBCFileUrl(null);
    setBC2FileUrl(null);
    setBirthCFrUrl(null);
    setBirthCFileUrl(null);
    setGSNFileUrl(null);

    setHasFileChanged({
      nic: false,
      nicB: false,
      birthCertificateBack: false,
      bankCopy: false,
      bankCopy2: false,
      birthCertificateFront: false,
      gramaNiladhariDiv: false,
    });
  };

  const resetNICFile = () => {
    setNICFile(null);
    setNICFileUrl(null);
    setHasFileChanged((prevState) => ({ ...prevState, nic: false }));
  };

  const resetNICBFile = () => {
    setNICFileB(null);
    setNICFileBUrl(null);
    setHasFileChanged((prevState) => ({ ...prevState, nicB: false }));
  };

  const resetBCFile = () => {
    setBCFile(null);
    setBCFileUrl(null);
    setHasFileChanged((prevState) => ({ ...prevState, bankCopy: false }));
  };

  const resetBC2File = () => {
    setBC2File(null);
    setBC2FileUrl(null);
    setHasFileChanged((prevState) => ({ ...prevState, bankCopy2: false }));
  };

  const resetBirthCertificateFrFile = () => {
    setBirthCFrFile(null);
    setBirthCFrUrl(null);
    setHasFileChanged((prevState) => ({
      ...prevState,
      birthCertificateFront: false,
    }));
  };

  const resetBirthCFile = () => {
    setBirthCFile(null);
    setBirthCFileUrl(null);
    setHasFileChanged((prevState) => ({
      ...prevState,
      birthCertificateBack: false,
    }));
  };

  const resetGSNFile = () => {
    setGSNFile(null);
    setGSNFileUrl(null);
    setHasFileChanged((prevState) => ({
      ...prevState,
      gramaNiladhariDiv: false,
    }));
  };

  //adding security officer
  const mutation = useMutation({
    mutationFn: async (securityPerson) => {
      const response = await axiosInstance.post(
        "/securityOfficers",
        securityPerson,
      );
      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of officers after adding a new officer
      await queryClient.invalidateQueries("securityOfficers" as any);
      toast.success("New Security Officer Added Successfully");
    },
    onError: async () => {
      toast.error("Could not add security officer details, Please try again");
    },
  });

  const updateMutation = useMutation({
    mutationFn: ({ officerId, securityPerson }: any) => {
      return axiosInstance.patch(
        `/securityOfficers/${officerId}`,
        securityPerson,
      );
    },
    onSuccess: async () => {
      // Refetch the list of officers after updating
      await queryClient.invalidateQueries("securityOfficers" as any);
      toast.success("Security Officer Details Updated Sccessfully");
    },
    onError: async () => {
      toast.error(
        "Could not update security officer details, Please try again",
      );
    },
  });

  const uploadMutation = useMutation({
    mutationFn: async ({
      file,
      folderName,
      fileId,
    }: {
      file: File;
      folderName: string;
      fileId?: string;
    }) => {
      return await uploadFileToS3(file, folderName, file.name, fileId);
    },
    onError: (error) => {
      console.error("Upload failed", error);
    },
  });

  const {
    fields: inventoryItems,
    append: inventoryAppend,
    remove: inventoryRemove,
  } = useFieldArray({
    control,
    name: "inventoryItems",
  });

  const handleAddNewInventoryButtonClick = () => {
    const newIndex = inventoryItems.length;
    inventoryAppend({
      itemId: selectedItemId,
      itemName: { itemId: "", itemName: "" },
      itemVarient: "",
      qty: 1,
      unitPrice: 0,
      totalPrice: 0,
    });
    setEditableRows((prev) => ({
      ...prev,
      [newIndex]: true,
    }));
  };

  const toggleCurrentRow = (index) => {
    setEditableRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleEditableRow = (index) => {
    setEditableRows((prev) => ({
      ...prev,
      [index]: false,
    }));
  };

  const InventoryRemoveRow = (index) => {
    inventoryRemove(index);

    setEditableRows((prev) => {
      const updated = { ...prev };
      delete updated[index];
      Object.keys(updated).forEach((key) => {
        const numKey = parseInt(key);
        if (numKey > index) {
          updated[numKey - 1] = updated[numKey];
          delete updated[numKey];
        }
      });
      return updated;
    });
  };

  //getting Inventory Item by Id to get the item varient list
  const { data: selectedItem } = useQuery({
    queryKey: ["InventoryItems", selectedItemId],
    queryFn: async () => {
      if (selectedItemId) {
        const response = await axiosInstance.get(
          `/inventory/${selectedItemId}`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      } //cmmented to commit
      return null;
    },
    enabled: !!selectedItemId, // Only fetch data if selectedItemId is truthy
  });

  const itemVarients = selectedItem?.itemVariants?.map(
    (varient) => varient?.variantType,
  );

  //getting Inventory Item by Id to get the item varient list
  const { refetch } = useQuery({
    // const { data: unitPrice, isSuccess : unitPriceIsSuccess,refetch } = useQuery({
    queryKey: ["InventoryItems", selectedItemId, selectedVarient],
    queryFn: async () => {
      if (selectedItemId && selectedVarient) {
        const response = await axiosInstance.get(
          `/securityOfficers/inventory/${selectedItemId}/${selectedVarient}`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      }
      return null;
    },
    enabled: !!selectedVarient, // Only fetch data if selectedVarient is truthy
  });

  const onSubmit: SubmitHandler<SecurityPerson> = async (formData) => {
    const transformInventoryItems = formData.inventoryItems.map((data) => {
      const {
        itemName: { itemId, itemName },
        ...rest
      } = data;
      return { ...rest, itemId, itemName };
    });
    let newFormData = { ...formData, inventoryItems: transformInventoryItems };
    const appLvl = formData?.appliedLevel;
    const offLvl = formData?.officerLevel;

    newFormData.appliedLevel =
      Levels.find((level) => level.label === appLvl)?.value || "";
    newFormData.officerLevel =
      Levels.find((level) => level.label === offLvl)?.value || "";

    if (!formData?.otherDocuments || formData?.otherDocuments?.length === 0) {
      newFormData.otherDocuments = [];
    }
    if (nicFile) {
      const data = hasFileChanged.nic
        ? await uploadMutation.mutateAsync({
            file: nicFile,
            folderName: "security-officer/nic",
            fileId: securityPerson?.nic?.s3Path?.split("/")[2],
          })
        : securityPerson?.nic?.s3Path;

      const nicObj = {
        fileName: nicFile.name,
        nicValue: formData.nic.nicValue,
        issuedDate: formData.nic.issuedDate,
        s3Path: data,
      };
      newFormData = { ...newFormData, nic: nicObj };
    } else {
      const nicObj = {
        fileName: null,
        nicValue: formData.nic.nicValue,
        issuedDate: formData.nic.issuedDate,
        s3Path: null,
      };
      newFormData = { ...newFormData, nic: nicObj };
    }

    if (nicFileB) {
      const data = hasFileChanged.nicB
        ? await uploadMutation.mutateAsync({
            file: nicFileB,
            folderName: "security-officer/nicBack",
            fileId: securityPerson?.nicBack?.s3Path?.split("/")[2],
          })
        : securityPerson?.nic?.s3Path;

      const nicObj = {
        fileName: nicFileB.name,
        nicValue: formData.nicBack.nicValue,
        // issuedDate: formData.nicBack.issuedDate,
        s3Path: data,
      };
      newFormData = { ...newFormData, nicBack: nicObj };
    } else {
      const nicObj = {
        fileName: null,
        nicValue: formData.nicBack.nicValue,
        // issuedDate: formData.nic.issuedDate,
        s3Path: null,
      };
      newFormData = { ...newFormData, nicBack: nicObj };
    }

    if (gsnFile) {
      const data = hasFileChanged.gramaNiladhariDiv
        ? await uploadMutation.mutateAsync({
            file: gsnFile,
            folderName: "security-officer/gsn",
            fileId: securityPerson?.gramaNiladhariDiv?.s3Path?.split("/")[2],
          })
        : securityPerson?.gramaNiladhariDiv?.s3Path;

      const gsnObj = {
        fileName: gsnFile.name,
        val: formData.gramaNiladhariDiv.val,
        s3Path: data,
      };
      newFormData = { ...newFormData, gramaNiladhariDiv: gsnObj };
    } else {
      const gsnObj = {
        fileName: null,
        val: formData.gramaNiladhariDiv.val,
        s3Path: null,
      };
      newFormData = { ...newFormData, gramaNiladhariDiv: gsnObj };
    }

    if (bcFile) {
      const data = hasFileChanged.bankCopy
        ? await uploadMutation.mutateAsync({
            file: bcFile,
            folderName: "security-officer/bank-copy",
            fileId: securityPerson?.bankCopy?.s3Path?.split("/")[2],
          })
        : securityPerson?.bankCopy?.s3Path;

      const bcObj = {
        fileName: bcFile.name,
        s3Path: data,
      };
      newFormData = { ...newFormData, bankCopy: bcObj };
    } else {
      const bcObj = {
        fileName: null,
        s3Path: null,
      };
      newFormData = { ...newFormData, bankCopy: bcObj };
    }

    if (bc2File) {
      const data = hasFileChanged.bankCopy2
        ? await uploadMutation.mutateAsync({
            file: bc2File,
            folderName: "security-officer/bank-copy-2",
            fileId: securityPerson?.bankCopy2?.s3Path?.split("/")[2],
          })
        : securityPerson?.bankCopy2?.s3Path;

      const bc2Obj = {
        fileName: bc2File.name,
        s3Path: data,
      };
      newFormData = { ...newFormData, bankCopy2: bc2Obj };
    } else {
      const bc2Obj = {
        fileName: null,
        s3Path: null,
      };
      newFormData = { ...newFormData, bankCopy2: bc2Obj };
    }

    if (birthCFile) {
      const data = hasFileChanged.birthCertificateBack
        ? await uploadMutation.mutateAsync({
            file: birthCFile,
            folderName: "security-officer/birth-certificate",
            fileId: securityPerson?.birthCertificateBack?.s3Path?.split("/")[2],
          })
        : securityPerson?.birthCertificateBack?.s3Path;

      const bcObj = {
        fileName: birthCFile.name,
        s3Path: data,
      };
      newFormData = { ...newFormData, birthCertificateBack: bcObj };
    } else {
      const bcObj = {
        fileName: null,
        s3Path: null,
      };
      newFormData = { ...newFormData, birthCertificateBack: bcObj };
    }

    if (birthCFrFile) {
      const data = hasFileChanged.birthCertificateFront
        ? await uploadMutation.mutateAsync({
            file: birthCFrFile,
            folderName: "security-officer/birth-certificate-front",
            fileId:
              securityPerson?.birthCertificateFront?.s3Path?.split("/")[2],
          })
        : securityPerson?.birthCertificateFront?.s3Path;

      const bcFrObj = {
        fileName: birthCFrFile.name,
        s3Path: data,
      };

      newFormData = { ...newFormData, birthCertificateFront: bcFrObj };
    } else {
      const bcFrObj = {
        fileName: null,
        s3Path: null,
      };
      newFormData = { ...newFormData, birthCertificateFront: bcFrObj };
    }

    if (capturedScreenshot) {
      // Convert base64 string from webcam to blob
      const fetchResponse = await fetch(capturedScreenshot);
      const blob = await fetchResponse.blob();

      const file = new File([blob], "snapshot.jpeg", { type: "image/jpeg" });

      const profilpicePath = await uploadFileToS3(
        file,
        `security-officer/profile-photos`,
        file.name,
        securityPerson?.profilePic?.split("/")[2],
      );

      newFormData = { ...newFormData, profilePic: profilpicePath };
    } else if (profilePhoto) {
      const fetchResponse = await fetch(profilePhoto);
      const blob = await fetchResponse.blob();

      const file = new File([blob], "snapshot.jpeg", { type: "image/jpeg" });

      const profilpicePath = await uploadFileToS3(
        file,
        `security-officer/profile-photos`,
        file.name,
        securityPerson?.profilePic?.split("/")[2],
      );

      newFormData = { ...newFormData, profilePic: profilpicePath };
      setCapturedScreenshot(profilpicePath);
    }

    // Perform add and edit based on mode
    if (isEdit && securityPerson) {
      await updateMutation.mutateAsync({
        officerId: securityPerson.id!,
        securityPerson: newFormData,
      } as any);
    } else {
      await mutation.mutateAsync(newFormData as any);
    }
    //
    //

    reset();
    onClose();
  };

  const StatusOptions = ["REJECTED", "APPROVED", "PENDING"];

  const currentStatusOptions = ["At Work", "On Leave", "Left"];

  const levelOptions = Levels.map((level) => level.label);

  //saving and displaying the screenshot
  const handleSaveScreenshot = async (screenshot: string) => {
    setCapturedScreenshot(screenshot);
    setisPhotoAddModal(false);
  };

  //clearing the screenshot when the modal is closed
  const handleCloseModal = () => {
    // setCapturedScreenshot(null);
    setisPhotoAddModal(false);
  };

  useEffect(() => {}, [hasFileChanged]);
  const handleFileSelect = (e, setter, urlSetter, fieldName?) => {
    const file: File = e.target.files[0];
    if (e.target.files.length > 0) {
      setter(file);
      setFileUrl(file, urlSetter);
      if (fieldName) {
        fieldName !== "gramaNiladhariDiv" && setValue(fieldName, file.name);
        setHasFileChanged((hasFileChanged) => ({
          ...hasFileChanged,

          [fieldName]: true,
        }));
      }

      //only nic has no fieldName passed
      if (!fieldName) {
        setHasFileChanged((hasFileChanged) => ({
          ...hasFileChanged,
          nic: true,
          nicB: true,
        }));
      }
    }
  };

  const setFileUrl = (file: File, setter) => {
    const fileUrl = URL.createObjectURL(file);
    setter(fileUrl);
  };

  const removeFile = (setter, urlSetter) => {
    setter(null);
    urlSetter(null);
  };

  const triggerFileInputClick = (ref) => {
    ref?.current?.click();
  };

  const viewFile = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const setFilesDataFromS3 = async (paths, fileSetter) => {
    if (!paths || paths.length === 0) {
      return;
    }
    const newFilePathsMap = {};

    // Download each file and create a File object
    const downloadPromises = paths.map(async (path) => {
      const [, blob] = await downloadFileFromS3(path);
      if (blob) {
        const fileName = path.split("/").pop() || "downloadedFile";
        newFilePathsMap[fileName] = path;

        return new File([blob], fileName, { type: (blob as Blob).type });
      }
      return null;
    });

    try {
      const files = await Promise.all(downloadPromises);
      const validFiles = files.filter((file) => file !== null);
      setFilePathsMap(newFilePathsMap);

      if (fileSetter && validFiles.length > 0) {
        fileSetter(validFiles);
      }
    } catch (error) {
      console.error("Error downloading files:", error);
    }
  };

  const setFileDataFromS3 = async (path: string, fileSetter?, urlSetter?) => {
    if (path) {
      const [url, blob] = await downloadFileFromS3(path);
      if (blob) {
        const fileName = path.split("/").pop() || path;
        const file = new File([blob], fileName, { type: (blob as Blob).type });
        fileSetter && fileSetter(file);
      }
      if (url && urlSetter) {
        urlSetter(url);
      }
    }
  };

  const updateFiles = (newFiles) => {
    (otherDocsRef.current as any)?.setUploadedFiles(newFiles);

    setOtherDocs(newFiles);
  };

  const acceptDelete = async (file) => {
    // (otherDocsRef.current as any)?.setFiles(
    //   (otherDocsRef.current as any)?.getFiles(),
    // );
    setDeleteFileInProgress(true);
    if (!file) {
      console.error("No file selected for deletion");
      return;
    }

    // Retrieve the file path using the file's name
    const filePathToDelete = filePathsMap[file.name];
    if (!filePathToDelete) {
      console.error("File path not found for the file being deleted");
      return;
    }

    // Attempt to delete the file from S3
    try {
      await deleteFileFromS3(filePathToDelete);

      // Update the UI to remove the deleted file
      const updatedFiles = otherDocs?.filter((exFile) => exFile !== file);

      updateFiles(updatedFiles);

      // Update the form state to remove the deleted file's path
      const currentFilePaths = getValues("otherDocuments") || [];
      const updatedFilePaths = currentFilePaths.filter(
        (path) => path !== filePathToDelete,
      );
      setValue("otherDocuments", updatedFilePaths);

      (toastRef.current as any)?.show({
        severity: "info",
        summary: "Deleted",
        detail: "File deleted successfully.",
        life: 3000,
      });

      // Remove the deleted file's entry from the filePathsMap
      const newFilePathsMap = { ...filePathsMap };
      delete newFilePathsMap[file.name];
      setFilePathsMap(newFilePathsMap);
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      setDeleteFileInProgress(false);
    }
  };

  const confirm = (event, file) => {
    confirmPopup({
      target: event.currentTarget,
      message:
        "Are you sure you want to delete this file? This cannot be undone.",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => acceptDelete(file),
    });
  };

  const handleSelectedProfilePic = (e: { files: File[] }) => {
    const file: File = e.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCapturedScreenshot(reader.result as string); // Set captured screenshot to the uploaded file's base64 string
        setProfilePhoto(file as any);
      };
      reader.readAsDataURL(file);
    }
  };

  //const totalPrice = quantity * unitPrice;

  // add drop downs to civil status, gender and religion
  const religion = ["Buddhist", "Hindu", "Muslim", "Christian", "Catholic"];
  const civilStatus = ["Married", "Single"];
  const gender = ["Male", "Female"];

  const religionOptions = religion.map((method) => method);
  const civilStatusOptions = civilStatus.map((method) => method);
  const genderOptions = gender.map((method) => method);

  return (
    <>
      <FormModal
        isOpen={isOpen}
        title={title}
        onClose={onClose}
        onSave={handleSubmit(onSubmit)}
        isLoading={
          updateMutation.isPending ||
          uploadMutation.isPending ||
          mutation.isPending
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {/** {isDataLoading && (
          <div>
            <SpinnerOverlay />
          </div>
        )}*/}
          <div className="flex flex-col space-y-10">
            <div className="flex w-full space-x-16">
              <div className={`'h-24 w-24'} flex items-center`}>
                {capturedScreenshot ? (
                  <>
                    <div className="flex flex-col space-y-4">
                      <div>
                        <Button
                          className={`flex items-center ${capturedScreenshot ? "h-[200px] w-[200px]" : "h-24 w-24"}`}
                          style={{
                            backgroundImage: capturedScreenshot
                              ? `url(${capturedScreenshot})`
                              : "none",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            borderRadius: "0px",
                          }}
                          text
                          onClick={() => {
                            setisPhotoAddModal(true);
                          }}
                          type="button"
                        >
                          <div className="flex flex-col items-center space-y-1">
                            <i
                              className="pi pi-camera"
                              style={{ fontSize: "1.7rem", color: "#02385B" }}
                            />
                            <span className="text-sm font-semibold capitalize text-primary-blue">
                              Photo
                            </span>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      className="!h-[200px] !w-[200px] !bg-[#DEE9F5] !shadow-none"
                      style={{ borderRadius: "0px" }}
                      text
                      onClick={() => {
                        setisPhotoAddModal(true);
                      }}
                    >
                      <div className="flex flex-col items-center space-y-1">
                        <i
                          className="pi pi-camera"
                          style={{ fontSize: "1.7rem", color: "#02385B" }}
                        />
                        <span className="text-sm font-semibold capitalize text-primary-blue">
                          Photo
                        </span>
                      </div>
                    </Button>
                  </>
                )}
              </div>

              <div>
                {/* <span>Reference No:</span> */}
                {/* <div className="font-bold">
                  {securityPerson?.refNo && isEdit
                    ? securityPerson?.refNo
                    : "Pending"}
                </div> */}
                <div>
                  {/* Below field is temporary until current SOs of the system are added. */}
                  <FormLabel label="Reference No:" />
                  <InputText
                    {...register("refNo", {
                      required: false,
                    })}
                    className="!bg-inputBg"
                    // placeholder="SO Name"
                  />
                </div>
                <div className=" mt-10">
                  <FileUpload
                    mode="basic"
                    name="profilePicture"
                    accept="image/*"
                    maxFileSize={1000000}
                    chooseLabel="Upload Profile Picture"
                    customUpload={true}
                    uploadHandler={handleSelectedProfilePic}
                  />
                </div>
              </div>
            </div>
            <div className=" text-lg font-semibold text-primary-text">
              Security Officer Details
            </div>
            <div>
              <FormLabel label="Full Name" required/>
              <InputText
                {...register("name", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg"
                // placeholder="SO Name"
              />
            </div>

            <div>
              <FormLabel label="Address" required/>
              <InputTextarea
                //placeholder="Address"
                color="blue"
                className=" !bg-inputBg !w-full"
                {...register("address", {
                  required: "This field is required.",
                })}
              />
            </div>

            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
              <div>
                <FormLabel label="Mobile No / Telephone No" required/>
                <InputText
                  {...register("mobileNo", {
                    required: "This field is required.",
                  })}

                  // placeholder="Mobile Number"
                />
              </div>
              <div>
                <FormLabel label="Applied Security Officer Level" required/>
                <Controller
                  name="appliedLevel"
                  control={control}
                  defaultValue={""}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Dropdown
                      value={field.value}
                      onChange={(e: DropdownChangeEvent) => {
                        field.onChange(e.value); // Update form field value
                      }}
                      options={levelOptions}
                      className="w-full"
                    />
                  )}
                />
              </div>
              <div>
                <FormLabel label="Date of Birth" required />

                <Controller
                  name="dob"
                  control={control}
                  render={({ field }) => (
                    <Calendar
                      id={field.name}
                      value={field.value as Date}
                      onChange={(e) => field.onChange(e.value)}
                      placeholder="DD - MM - YYYY"
                      showIcon
                      className="w-full"
                      dateFormat="dd/mm/yy"
                    />
                  )}
                />
              </div>
              <div>
                <FormLabel label="City of Residence" />
                <InputText
                  {...register("cityOfResidence")}
                  // placeholder="City of Residence"
                />
              </div>

              <div>
                <FormLabel label="District" />
                <InputText
                  {...register("district")}

                  // placeholder="District"
                />
              </div>

              <div>
                <FormLabel label="Grama Niladhari Division" />

                <span className="p-input-icon-left w-full">
                  <i
                    title="Upload"
                    onClick={() => triggerFileInputClick(gsnFileInputRef)}
                    className="pi pi-camera  right-[10px] flex cursor-pointer items-center hover:text-primary-blue"
                  />
                  <InputText
                    {...register("gramaNiladhariDiv.val", {
                      required: false,
                    })}
                  />
                  {gsnFile && gsnFileUrl && (
                    <>
                      <i
                        title="View"
                        onClick={() => viewFile(gsnFileUrl)}
                        className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                      />

                      <i
                        title="Delete"
                        onClick={() => removeFile(setGSNFile, setGSNFileUrl)}
                        className="pi pi-trash right-8   flex cursor-pointer items-center  border-r  px-1 hover:text-primary-blue"
                      />
                    </>
                  )}
                </span>

                <input
                  type="file"
                  ref={gsnFileInputRef}
                  hidden
                  onChange={(e) =>
                    handleFileSelect(
                      e,
                      setGSNFile,
                      setGSNFileUrl,
                      "gramaNiladhariDiv",
                    )
                  }
                />
              </div>

              <div>
                <FormLabel label="Police Division" />
                <InputText
                  {...register("policeDiv")}
                  // placeholder="Police Division"
                />
              </div>

              <div>
                <FormLabel label="Religion" />
                <Controller
                  name="religion"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      options={religionOptions}
                      className="w-full"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>

              <div>
                <FormLabel label="Civil Status" />
                <Controller
                  name="civilStatus"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      options={civilStatusOptions}
                      className="w-full"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>

              <div>
                <FormLabel label="Gender" />
                <Controller
                  name="gender"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      options={genderOptions}
                      className="w-full"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
              <div>
                <FormLabel label="Blood Group" />
                <InputText
                  {...register("bloodGroup")}
                  // placeholder="Gender"
                />
              </div>
              <div>
                <FormLabel label="Illness details (if any)" />

                <InputTextarea
                  color="blue"
                  {...register("illnessDetails", {
                    required: false,
                  })}
                  autoResize
                  rows={1}
                  className="w-full"
                />
              </div>
            </div>
            <div className="grid grid-cols-1  gap-2 lg:grid-cols-2  lg:gap-10">
            <div className="flex flex-row items-center space-x-6">
              <div className="flex flex-row items-center">
                <Controller
                  name="isEPF"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <InputSwitch
                      onChange={(value) => {
                        field.onChange(value);
                        setIsEPFEnabled(value.value);
                      }}
                      checked={field.value}
                      className=" mr-4"
                    />
                  )}
                />
                <span className="text-textColor  ml-2 text-sm font-normal">
                  EPF
                </span>
              </div>
              <div>
                {isEPFEnabled && (
                  <div className=" ml-4">
                    <FormLabel label="EPF Number" required/>

                    <InputText
                      color="blue"
                      {...register("EPFnumber", {
                        required: true,
                      })}
                      className="w-[445px]"
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-row items-center">
                <Controller
                  name="isETF"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <InputSwitch
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      checked={field.value}
                      className=" mr-4"
                    />
                  )}
                />
                <span className="text-textColor  ml-2 text-sm font-normal">
                  ETF
                </span>
              </div>
            </div>
            <div>
                <FormLabel label="Joined Date" />
                <Controller
                  name={`joinedDate`}
                  control={control}
                  render={({ field }) => (
                    <Calendar
                      id={field.name}
                      value={field.value as Date}
                      onChange={(e) => field.onChange(e.value)}
                      placeholder="DD - MM - YYYY"
                      showIcon
                      className="w-full"
                      dateFormat="dd/mm/yy"
                      //style={{ width: "400px" }}
                    />
                  )}
                />
              </div>
              </div>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10"></div>
            <div className="grid grid-cols-1  gap-2 lg:grid-cols-2  lg:gap-10">
              <div className=" text-lg font-semibold text-primary-text">
                Identification Details
              </div>
              {/* <div className="  text-lg font-semibold text-primary-text">
                Qualification Details
              </div> */}
            </div>
            <div className="flex flex-col space-y-4">
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-14">
                <div>
                  <FormLabel label="NIC - front" />

                  <span className="p-input-icon-left w-full">
                    <i
                      title="Upload"
                      onClick={() => {
                        triggerFileInputClick(nicFileInputRef);
                      }}
                      className="pi pi-camera  right-[10px] flex cursor-pointer items-center hover:text-primary-blue"
                    />
                    <InputText
                      {...register("nic.nicValue", {
                        required: false,
                      })}
                    />
                    {nicFile && nicFileUrl && (
                      <>
                        <i
                          title="View"
                          onClick={() => viewFile(nicFileUrl)}
                          className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                        />

                        <i
                          title="Delete"
                          onClick={() => removeFile(setNICFile, setNICFileUrl)}
                          className="pi pi-trash right-8   flex cursor-pointer items-center  border-r  px-1 hover:text-primary-blue"
                        />
                      </>
                    )}
                  </span>

                  <input
                    type="file"
                    ref={nicFileInputRef}
                    hidden
                    onChange={(e) =>
                      handleFileSelect(e, setNICFile, setNICFileUrl)
                    }
                  />
                </div>

                <div>
                  <FormLabel label="Birth Certificate - front" />

                  <span className="p-input-icon-left w-full">
                    <i
                      title="Upload"
                      onClick={() =>
                        triggerFileInputClick(birthCFrFileInputRef)
                      }
                      className="pi pi-camera  right-[10px] flex cursor-pointer items-center hover:text-primary-blue"
                    />
                    <InputText {...register("birthCertificateFront", {})} />
                    {birthCFrFile && birthCFrUrl && (
                      <>
                        <i
                          title="View"
                          onClick={() => viewFile(birthCFrUrl)}
                          className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                        />

                        <i
                          title="Delete"
                          onClick={() =>
                            removeFile(setBirthCFrFile, setBirthCFrUrl)
                          }
                          className="pi pi-trash right-8   flex cursor-pointer items-center  border-r  px-1 hover:text-primary-blue"
                        />
                      </>
                    )}
                  </span>

                  <input
                    type="file"
                    ref={birthCFrFileInputRef}
                    hidden
                    onChange={(e) =>
                      handleFileSelect(
                        e,
                        setBirthCFrFile,
                        setBirthCFrUrl,
                        "birthCertificateFront",
                      )
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-14">
                <div>
                  <FormLabel label="NIC - back" />

                  <span className="p-input-icon-left w-full">
                    <i
                      title="Upload"
                      onClick={() => {
                        triggerFileInputClick(nicFileBInputRef);
                      }}
                      className="pi pi-camera  right-[10px] flex cursor-pointer items-center hover:text-primary-blue"
                    />
                    <InputText
                      {...register("nicBack.nicValue", {
                        required: false,
                      })}
                    />
                    {nicFileB && nicFileBUrl && (
                      <>
                        <i
                          title="View"
                          onClick={() => viewFile(nicFileBUrl)}
                          className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                        />

                        <i
                          title="Delete"
                          onClick={() =>
                            removeFile(setNICFileB, setNICFileBUrl)
                          }
                          className="pi pi-trash right-8   flex cursor-pointer items-center  border-r  px-1 hover:text-primary-blue"
                        />
                      </>
                    )}
                  </span>

                  <input
                    type="file"
                    ref={nicFileBInputRef}
                    hidden
                    onChange={(e) =>
                      handleFileSelect(e, setNICFileB, setNICFileBUrl)
                    }
                  />
                </div>

                <div>
                  <FormLabel label="Birth Certificate - back" />

                  <span className="p-input-icon-left w-full">
                    <i
                      title="Upload"
                      onClick={() => triggerFileInputClick(birthCFileInputRef)}
                      className="pi pi-camera  right-[10px] flex cursor-pointer items-center hover:text-primary-blue"
                    />
                    <InputText {...register("birthCertificateBack", {})} />
                    {birthCFile && birthCFileUrl && (
                      <>
                        <i
                          title="View"
                          onClick={() => viewFile(birthCFileUrl)}
                          className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                        />

                        <i
                          title="Delete"
                          onClick={() =>
                            removeFile(setBirthCFile, setBirthCFileUrl)
                          }
                          className="pi pi-trash right-8   flex cursor-pointer items-center  border-r  px-1 hover:text-primary-blue"
                        />
                      </>
                    )}
                  </span>

                  <input
                    type="file"
                    ref={birthCFileInputRef}
                    hidden
                    onChange={(e) =>
                      handleFileSelect(
                        e,
                        setBirthCFile,
                        setBirthCFileUrl,
                        "birthCertificateBack",
                      )
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-14">
                <div>
                  <FormLabel label="NIC Issued Date" />

                  <Controller
                    name="nic.issuedDate"
                    control={control}
                    render={({ field }) => (
                      <Calendar
                        id={field.name}
                        value={field.value as Date}
                        onChange={(e) => field.onChange(e.value)}
                        placeholder="DD - MM - YYYY"
                        showIcon
                        className=" w-full"
                        dateFormat="dd/mm/yy"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col  pt-8">
              <div className="flex flex-row space-x-14">
                <div className="  text-lg font-semibold text-primary-text">
                  Dependents Details
                </div>
              </div>
              <div className="my-8 flex items-center justify-between rounded-sm border bg-slate-50 px-3 py-3">
                <span className=" text-sm font-semibold">Dependent 1</span>
                <div className="flex items-center">
                  <Controller
                    name="dependents.0.isEmergencyContact"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <InputSwitch
                        onChange={(value) => field.onChange(value)}
                        checked={field.value}
                        className=" mr-4"
                      />
                    )}
                  />
                  <span className="text-textColor  ml-2 text-sm font-normal">
                    Contact in case of Emergency
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
                <div>
                  <FormLabel label="Name" />
                  <InputText
                    {...register("dependents.0.name", {
                      required: false,
                    })}
                  />
                </div>
                <div>
                  <FormLabel label="Relationship to SO" />
                  <InputText
                    {...register("dependents.0.relationship", {
                      required: false,
                    })}
                  />
                </div>
                <div>
                  <FormLabel label="Contact No" />
                  <InputText
                    {...register("dependents.0.contactNo", {
                      required: false,
                    })}
                  />
                </div>
                <div>
                  <FormLabel label="Address" />

                  <InputTextarea
                    color="blue"
                    {...register("dependents.0.address", {
                      required: false,
                    })}
                    autoResize
                    rows={1}
                    className="w-full"
                  />
                </div>
              </div>

              <div className="my-8 flex items-center justify-between rounded-sm border bg-slate-50 px-3 py-3">
                <span className=" text-sm font-semibold">Dependent 2</span>
                <div className="flex items-center">
                  <Controller
                    name="dependents.1.isEmergencyContact"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <InputSwitch
                        onChange={(value) => field.onChange(value)}
                        checked={field.value}
                        className=" mr-4"
                      />
                    )}
                  />
                  <span className="text-textColor  ml-2 text-sm font-normal">
                    Contact in case of Emergency
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
                <div>
                  <FormLabel label="Name" />
                  <InputText
                    {...register("dependents.1.name", {
                      required: false,
                    })}
                  />
                </div>
                <div>
                  <FormLabel label="Relationship to SO" />
                  <InputText
                    {...register("dependents.1.relationship", {
                      required: false,
                    })}
                  />
                </div>
                <div>
                  <FormLabel label="Contact No" />
                  <InputText
                    {...register("dependents.1.contactNo", {
                      required: false,
                    })}
                  />
                </div>
                <div>
                  <FormLabel label="Address" />

                  <InputTextarea
                    color="blue"
                    {...register("dependents.1.address", {
                      required: false,
                    })}
                    autoResize
                    rows={1}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-4 pt-8">
              <div className="flex flex-row space-x-14">
                <div className="  text-lg font-semibold text-primary-text">
                  Bank Details
                </div>
              </div>
              <div className="my-8 flex w-full items-center justify-between rounded-sm border bg-slate-50 px-3 py-3">
                <span className=" text-sm font-semibold">Bank Details 1</span>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
                <div>
                  <FormLabel label="Bank Name" />
                  <InputText
                    {...register("bankName", {
                      required: false,
                    })}
                    //placeholder="Bank Name"
                  />
                </div>
                <div>
                  <FormLabel label="Account No" />
                  <InputText
                    {...register("accNo", {
                      required: false,
                    })}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
              <div>
                <FormLabel label="Bank Branch" />
                <InputText
                  {...register("bankBranch", {
                    required: false,
                  })}
                />
              </div>
              <div>
                {/* <FormLabel label="Bank Copy" />

                <span className="p-input-icon-left w-full">
                  <i
                    title="Upload"
                    onClick={() => triggerFileInputClick(bcFileInputRef)}
                    className="pi pi-camera  right-[10px] flex cursor-pointer items-center hover:text-primary-blue"
                  />
                  <InputText
                    {...register("bankCopy", {
                      required: false,
                    })}
                  />
                  {bcFile && bcFileUrl && (
                    <>
                      <i
                        title="View"
                        onClick={() => viewFile(bcFileUrl)}
                        className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                      />
                      <i
                        title="Delete"
                        onClick={() => removeFile(setBCFile, setBCFileUrl)}
                        className="pi pi-trash right-8   flex cursor-pointer items-center  border-r  px-1 hover:text-primary-blue"
                      />
                    </>
                  )}
                </span>

                <input
                  type="file"
                  ref={bcFileInputRef}
                  hidden
                  onChange={(e) =>
                    handleFileSelect(e, setBCFile, setBCFileUrl, "bankCopy")
                  }
                /> */}
              </div>
            </div>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-row space-x-14">
                <div className="my-8 flex w-full items-center justify-between rounded-sm border bg-slate-50 px-3 py-3">
                  <span className=" text-sm font-semibold">Bank Details 2</span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
                <div>
                  <FormLabel label="Bank Name" />
                  <InputText
                    {...register("bankName2", {
                      required: false,
                    })}
                    //placeholder="Bank Name"
                  />
                </div>
                <div>
                  <FormLabel label="Account No" />
                  <InputText
                    {...register("accNo2", {
                      required: false,
                    })}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
              <div>
                <FormLabel label="Bank Branch" />
                <InputText
                  {...register("bankBranch2", {
                    required: false,
                  })}
                />
              </div>
              <div>
                {/* <FormLabel label="Bank Copy" />

                <span className="p-input-icon-left w-full">
                  <i
                    title="Upload"
                    onClick={() => triggerFileInputClick(bc2FileInputRef)}
                    className="pi pi-camera  right-[10px] flex cursor-pointer items-center hover:text-primary-blue"
                  />
                  <InputText
                    {...register("bankCopy2", {
                      required: false,
                    })}
                  />
                  {bc2File && bc2FileUrl && (
                    <>
                      <i
                        title="View"
                        onClick={() => viewFile(bc2FileUrl)}
                        className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                      />

                      <i
                        title="Delete"
                        onClick={() => removeFile(setBC2File, setBC2FileUrl)}
                        className="pi pi-trash right-8   flex cursor-pointer items-center  border-r  px-1 hover:text-primary-blue"
                      />
                    </>
                  )}
                </span>

                <input
                  type="file"
                  ref={bc2FileInputRef}
                  hidden
                  onChange={(e) =>
                    handleFileSelect(e, setBC2File, setBC2FileUrl, "bankCopy2")
                  }
                /> */}
              </div>
            </div>
            <div className=" pt-8 text-lg font-semibold text-primary-text">
              Supporting Documents
              <br />
              <span className="text-sm font-normal text-primary-blueLigter">
                Please include all the Educational Qualification documents and
                other supporting documents into one PDF file and upload the PDF
                file.
              </span>
            </div>

            <div className="card">
              {uploadMutation.isPending || deleteFileInProgress ? (
                <ProgressBar mode="indeterminate"></ProgressBar>
              ) : (
                <></>
              )}
              <FileUpload
                cancelOptions={{ className: "hidden" }}
                ref={otherDocsRef}
                customUpload
                itemTemplate={(file) => {
                  return (
                    <div className="my-2 flex justify-between">
                      <div className="flex space-x-6 ">
                        <div>{(file as File).name}</div>
                        <div
                          className={`rounded-lg ${otherDocs?.includes(file as File) ? "bg-blue-100" : "bg-yellow-100"} px-3 py-1`}
                        >
                          {otherDocs?.includes(file as File)
                            ? "Complete"
                            : "Pending"}
                        </div>
                      </div>

                      <div className="space-x-4">
                        <i
                          title="View"
                          onClick={() => {
                            const url = URL.createObjectURL(file as File);

                            viewFile(url);
                          }}
                          className="pi pi-eye  flex cursor-pointer items-center  px-1 hover:text-primary-blue"
                        />
                        <Toast ref={toastRef} />
                        <ConfirmPopup />
                        <i
                          title="Delete"
                          onClick={(e) => {
                            const isPrevFile = otherDocs?.includes(
                              file as File,
                            );
                            if (isPrevFile) {
                              confirm(e, file);
                            } else {
                              const newFilesList = (
                                otherDocsRef?.current as any
                              )?.getFiles();
                              (otherDocsRef?.current as any)?.setFiles(
                                newFilesList.filter((f) => f !== file),
                              );
                            }
                          }}
                          className="pi pi-trash   flex cursor-pointer items-center   px-1 hover:text-primary-blue"
                        />
                      </div>
                    </div>
                  );
                }}
                // onRemove={(e) =>  }
                // progressBarTemplate={
                //   uploadMutation.isPending || deleteFileInProgress ? (
                //     <ProgressBar mode="indeterminate"></ProgressBar>
                //   ) : undefined
                // }
                uploadHandler={async (e) => {
                  const existingFiles = otherDocs || [];

                  const uploadPromises = e.files.map((file) => {
                    return uploadMutation
                      .mutateAsync({
                        file: file,
                        folderName: "security-officer/other-docs",
                      })
                      .then((data) => {
                        return { fileName: file.name, filePath: data };
                      });
                  });

                  try {
                    const uploadResults = await Promise.all(uploadPromises);
                    const previousPaths: string[] =
                      getValues("otherDocuments") || [];
                    const newPaths = uploadResults.map(
                      (result) => result.filePath,
                    );

                    setValue("otherDocuments", [...previousPaths, ...newPaths]);
                    updateFiles([...existingFiles, ...e.files]);

                    const newFilePathsMap = { ...filePathsMap };
                    uploadResults.forEach(({ fileName, filePath }) => {
                      newFilePathsMap[fileName] = filePath;
                    });

                    setFilePathsMap(newFilePathsMap);
                    (otherDocsRef.current as any)?.setFiles();

                    // Clear the files
                    // (otherDocsRef.current)?.clear();
                  } catch (error) {
                    (toastRef.current as any).show({
                      severity: "warn",
                      summary: "Error",
                      detail: "Error uploading files",
                      life: 3000,
                    });
                  }
                }}
                multiple
                accept="/*"
                maxFileSize={11000000}
                // headerTemplate={<span>hello</span>}
                emptyTemplate={
                  <p className="m-0">Drag and drop files to here to upload.</p>
                }
              />
            </div>

            <div className=" pt-8 text-lg font-semibold text-primary-text">
              Previous Work Details
            </div>
            <div>
              <FormLabel label="Work Experience" />

              <InputTextarea
                color="blue"
                {...register("workExp.workExpDetails", {
                  required: false,
                })}
                autoResize
                rows={1}
                className="w-full"
              />
            </div>
            <div className="flex flex-row space-x-10">
              <div className="flex items-center">
                <Controller
                  name="workExp.wasArmedForces"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <InputSwitch
                      onChange={(value) => field.onChange(value)}
                      checked={field.value}
                      className=" mr-4"
                    />
                  )}
                />
                <span className="text-textColor  ml-2 text-sm font-normal">
                  Was part of Army Forces
                </span>
              </div>
              <div>
                <FormLabel label="Remarks" />
                <InputText
                  {...register("workExp.armedForcesRemarks", {
                    required: false,
                  })}
                  className="!bg-inputBg !w-[550px]"
                  // placeholder="Remarks"
                />
              </div>
            </div>
            <div className="space-y-10 pt-8">
              <div className=" text-lg font-semibold text-primary-text">
                Interview Status & Feedback
              </div>

              <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
                <div>
                  <FormLabel label="Interview Feedback" />

                  <InputTextarea
                    color="blue"
                    {...register("interviewFeedback", {
                      required: false,
                    })}
                    autoResize
                    rows={1}
                    className="w-full"
                  />
                </div>

                <div>
                  <FormLabel label="Officer Level" />

                  <Controller
                    name="officerLevel"
                    control={control}
                    defaultValue={""}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Dropdown
                        value={field.value}
                        onChange={(e: DropdownChangeEvent) => {
                          field.onChange(e.value); // Update form field value
                        }}
                        options={levelOptions}
                        className="w-full"
                      />
                    )}
                  />
                </div>
                <div>
                  <FormLabel label="Interview Result" />
                  <Controller
                    name="interviewStatus"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Dropdown
                        value={field.value}
                        onChange={(e: DropdownChangeEvent) => {
                          field.onChange(e.value); // Update form field value
                        }}
                        options={StatusOptions}
                        className="w-full"
                      />
                    )}
                  />
                </div>
                <div>
                  <FormLabel label=" Basic Salary (LKR)" />

                  <Controller
                    control={control}
                    name={`basicSalary`}
                    render={({ field }) => (
                      <InputNumber
                        value={field.value}
                        onValueChange={(e) => field.onChange(e.value)}
                        useGrouping={true}
                        minFractionDigits={2}
                      />
                    )}
                  />
                </div>
                <div>
                  <FormLabel label="Day Shift Payment (LKR)" />

                  <Controller
                    control={control}
                    name={`dayShiftPayment`}
                    render={({ field }) => (
                      <InputNumber
                        value={field.value}
                        onValueChange={(e) => field.onChange(e.value)}
                        useGrouping={true}
                        minFractionDigits={2}
                      />
                    )}
                  />
                </div>
                <div>
                  <FormLabel label="Night Shift Payment (LKR)" />

                  <Controller
                    control={control}
                    name={`nightShiftPayment`}
                    render={({ field }) => (
                      <InputNumber
                        value={field.value}
                        onValueChange={(e) => field.onChange(e.value)}
                        useGrouping={true}
                        minFractionDigits={2}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-row space-x-10">
                  {interviewStatus === "APPROVED" && (
                    <div className="flex items-center justify-between">
                      <Controller
                        name="activeStatus"
                        control={control}
                        render={({ field }) => (
                          <InputSwitch
                            onChange={(e: InputSwitchChangeEvent) => {
                              field.onChange(e.value); //update the input switch value
                              // if (!e.value) {
                              //   const inactivatedDate = new Date();
                              //   setInactivationDate(inactivatedDate);
                              // }
                            }}
                            checked={field.value}
                            className="mr-4"
                          />
                        )}
                      />
                      {activeStatus ? (
                        <span className="text-textColor pr-120 ml-2 text-sm font-normal">
                          ACTIVE
                        </span>
                      ) : (
                        <span className="text-textColor pr-120 ml-2 text-sm font-normal">
                          INACTIVE
                        </span>
                      )}

                      {/* Display the current date when security is inactive */}
                      {!activeStatus && (
                        <div className="flex flex-row space-x-4 pl-10">
                          <div className="">
                            <FormLabel label="Inactivated Date" />
                            <Controller
                              control={control}
                              name={`inactivatedDate`}
                              render={({field}) => (
                                <Calendar
                                   value={field.value as Date}
                                    onChange={(e) => field.onChange(e.value)}
                                  className="!w-[120px]"
                                  dateFormat="dd/mm/yy"
                                />
                              )}
                            />
                          </div>
                          <div>
                            <FormLabel label="Inactivated Reason" />
                            <InputText
                              className="!w-[400px]"
                              {...register("inactivatedReason", {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                      )}
                      <div className=" ml-10">
                        <FormLabel label="Current Status" />
                        <Controller
                          name="currentStatus"
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <Dropdown
                              value={field.value}
                              onChange={(e: DropdownChangeEvent) => {
                                field.onChange(e.value); // Update form field value
                              }}
                              options={currentStatusOptions}
                              className="w-full"
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row space-x-6">
              <div className=" mt-10 text-lg font-semibold text-primary-text">
                Allocation of Inventory Items
              </div>
            </div>
            <div>
              <Button
                type="button"
                className="flex !h-11 !w-[250px] items-center space-x-2 shadow-none lg:w-auto"
                onClick={() => {
                  handleAddNewInventoryButtonClick();
                }}
              >
                Add Inventory Item
              </Button>
            </div>
            <div>
              <table className=" mt-3 w-full">
                <thead>
                  <tr>
                    {tableHeaders.map((head) => (
                      <th
                        key={head}
                        className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-start text-sm font-semibold text-primary-text"
                      >
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {inventoryItems.map((item, index) => {
                    return (
                      <tr key={index}>
                        {editableRows[index] ? (
                          <>
                            <td className="p-4">
                              {/* <InputText  {...register(`inventoryItems.${index}.itemId`)} hidden value={selectedItemId}/> */}
                              <div className="card justify-content-center flex">
                                <Controller
                                  name={`inventoryItems.${index}.itemName`}
                                  control={control}
                                  rules={{
                                    required: "This field is required.",
                                  }}
                                  render={({ field }) => (
                                    <Dropdown
                                      id={field.name}
                                      value={field.value}
                                      onChange={(e: DropdownChangeEvent) => {
                                        const selectedOption = e.value;
                                        const selectedId =
                                          selectedOption.itemId;
                                        //const selectedItemName = selectedOption.itemName;

                                        field.onChange(selectedOption);
                                        setSelectedItemId(selectedId);
                                      }}
                                      options={items}
                                      placeholder="Select items"
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td className="p-4">
                              <div className="card justify-content-center flex">
                                <Controller
                                  name={`inventoryItems.${index}.itemVarient`}
                                  control={control}
                                  rules={{
                                    required: "This field is required.",
                                  }}
                                  render={({ field }) => (
                                    <Dropdown
                                      id={field.name}
                                      value={field.value}
                                      onChange={async (
                                        e: DropdownChangeEvent,
                                      ) => {
                                        const selectedValue = e.value;
                                        field.onChange(e.value);
                                        setSelectedVarient(selectedValue);
                                        const { data, isSuccess } =
                                          await refetch();
                                        if (isSuccess) {
                                          setValue(
                                            `inventoryItems.${index}.unitPrice`,
                                            data,
                                          );
                                          setValue(
                                            `inventoryItems.${index}.totalPrice`,
                                            data * quantity,
                                          );
                                        }
                                      }}
                                      options={itemVarients}
                                      placeholder="Select varient"
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td className="p-4">
                              <Controller
                                name={`inventoryItems.${index}.qty`}
                                control={control}
                                rules={{ required: "This field is required." }}
                                render={({ field }) => (
                                  <InputNumber
                                    id={field.name}
                                    value={field.value}
                                    onValueChange={(
                                      e: InputNumberValueChangeEvent,
                                    ) => {
                                      const qty = e.value;
                                      field.onChange(e.value);
                                      setQuantity(qty as any);
                                      const data = getValues(
                                        `inventoryItems.${index}.unitPrice`,
                                      );
                                      if (data && qty) {
                                        setValue(
                                          `inventoryItems.${index}.totalPrice`,
                                          data * qty,
                                        );
                                      }
                                    }}
                                    mode="decimal"
                                    showButtons
                                    min={0}
                                  />
                                )}
                              />
                            </td>
                            <td className="p-4">
                              <Controller
                                name={`inventoryItems.${index}.unitPrice`}
                                control={control}
                                rules={{ required: "This field is required." }}
                                render={({ field }) => (
                                  <InputNumber
                                    id={field.name}
                                    value={field.value}
                                    onValueChange={(
                                      e: InputNumberValueChangeEvent,
                                    ) => field.onChange(e.value)}
                                    mode="decimal"
                                    useGrouping={true}
                                    minFractionDigits={2}
                                    disabled
                                  />
                                )}
                              />
                            </td>
                            <td className="p-4">
                              <Controller
                                name={`inventoryItems.${index}.totalPrice`}
                                control={control}
                                rules={{ required: "This field is required." }}
                                render={({ field }) => (
                                  <InputNumber
                                    id={field.name}
                                    value={field.value}
                                    onValueChange={(
                                      e: InputNumberValueChangeEvent,
                                    ) => field.onChange(e.value)}
                                    mode="decimal"
                                    useGrouping={true}
                                    minFractionDigits={2}
                                    disabled
                                  />
                                )}
                              />
                            </td>
                            <td className="p-4">
                              <span className="flex flex-row space-x-3">
                                <i
                                  className="pi pi-check cursor-pointer"
                                  onClick={() => {
                                    toggleEditableRow(index);
                                    handleAddNewInventoryButtonClick();
                                  }}
                                />
                                <i
                                  className="pi pi-times cursor-pointer"
                                  onClick={() => InventoryRemoveRow(index)}
                                />
                              </span>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="p-4">{item.itemName.itemName}</td>
                            <td className="p-4">{item.itemVarient}</td>
                            <td className="p-4">{item.qty}</td>
                            <td className="p-4">{item.unitPrice}</td>
                            <td className="p-4">{item.totalPrice}</td>
                            <td className="p-2">
                              <span className="flex flex-row ">
                                <Button
                                  icon="pi pi-pencil"
                                  className=" !w-2 !border-none !bg-white !p-3 !text-primary-blue !shadow-none"
                                  onClick={() => {
                                    toggleCurrentRow(index);
                                  }}
                                  type="button"
                                />
                                <Button
                                  type="button"
                                  icon="pi pi-trash"
                                  className="!w-2 !border-none !bg-white !p-3 !text-primary-blue !shadow-none"
                                  onClick={() => {
                                    InventoryRemoveRow(index);
                                  }}
                                />
                              </span>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </FormModal>
      <PhotoModal
        isOpen={isOpenPhotoModal}
        onClose={handleCloseModal}
        onSave={handleSaveScreenshot as any}
      />
    </>
  );
};

export default AddEditSecurityPerson;
