import { Months } from "@/constants/common";
import { Button } from "primereact/button";
// import { Calendar } from "primereact/calendar";
import { TAdvancePayment } from "@/models/advancePayment";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Calendar } from "primereact/calendar";
//import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { FC, useEffect, useState } from "react";
import { AiFillDollarCircle } from "react-icons/ai";
import { TiDownload } from "react-icons/ti";
import { toast } from "react-toastify";
import DotIcon from "../../../../assets/dot.svg?react";
import AddEditAdvance from "./AddAdvancePayment";

const tableHeaders = [
  "Ref No.",
  "Employee Name",
  "Day Shifts Covered",
  "Night Shifts Covered",
  "Total Shifts Covered",
  "Already Claimed Advance for the Month",
];

const AdvancePayment: FC = () => {
  const [searchTerms, setSearchTerms] = useState("");
  //const [selectedItem, setSelectedItem] = useState(null);
  // const [date, setDate] = useState(new Date());
  const [isOpenAddModal, setisOpenAddModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [filteredPayments, setFilteredPayments] = useState<TAdvancePayment[]>(
    [],
  );

  const [selectedOfficerId, setSelectedOfficerId] = useState("");
  const [selectedPaymentId, setSelectedPaymentId] = useState("");

  // const items = Levels.map((level) => level.value);

  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const monthString = Months[monthIndex];

  //get All advanced payment objects
  const { data: payments } = useQuery({
    queryKey: ["AdvancePayments", year, monthString],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/advancePayment/${year}/${monthString}`,
      );
      if (!response) {
        throw new Error("Network response was not ok");
      }
      return response.data;
    },
  });

  const toggleDescription = (id: string) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  // //searching name
  // const { mutate: searchMutation } = useMutation({
  //   mutationFn: async (searchTerm) => {
  //     try {
  //
  //       const response = await axiosInstance.post("/advancePayment/search", {
  //         searchTerm,
  //       });
  //       return response.data;
  //     } catch (error) {
  //       throw new Error("Error searching payments by officer name");
  //     }
  //   },
  //   onSuccess: async (data) => {
  //
  //     setFilteredPayments(data);
  //   },
  // });

  //search while typing
  useEffect(() => {
    if (searchTerms) {
      //searchMutation(searchTerms as any);

      const filteredPayments = searchTerms
        ? payments.filter(
            (item) =>
              item.name.toLowerCase().includes(searchTerms.toLowerCase()) ||
              (item.refNo && item.refNo.toLowerCase().includes(searchTerms)),
          )
        : payments;

      setFilteredPayments(filteredPayments);
    } else {
      setFilteredPayments(payments);
    }
  }, [searchTerms, payments]);

  //filering by rank
  // const { mutate: filterMutation } = useMutation({
  //   mutationFn: async (rank) => {
  //     try {
  //
  //       const response = await axiosInstance.post("/advancePayment/filter", {
  //         rank,
  //       });
  //       return response.data;
  //     } catch (error) {
  //       throw new Error("Error filtering advance payments");
  //     }
  //   },
  //   onSuccess: async (data) => {
  //
  //     setFilteredPayments(data);
  //   },
  // });

  useEffect(() => {
    if (payments) {
      setFilteredPayments(payments);
    }
  }, [payments]);

  // const handleRankChange = (e: DropdownChangeEvent) => {
  //   const { value } = e;
  //   setSelectedItem(value);
  //   filterMutation(value as any);
  // };

  const handleClearFilters = async () => {
    setSearchTerms("");
    //setSelectedItem(null);
    setFilteredPayments(payments);
  };

  const queryClient = useQueryClient();

  // updating payroll
  const mutation = useMutation({
    mutationFn: async ({ officerId, paymentId, status }: any) => {
      const postData = {
        status: status,
      };

      const response = await axiosInstance.patch(
        `/advancePayment/${officerId}/${paymentId}/${year}/${monthString}`,
        postData,
      );

      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of officer payroll after updating individual payroll
      await queryClient.invalidateQueries("AdvancePayments" as any);
      toast.success("Payment Status Updated Succesfully");
    },
  });

  //get individual approved payment object
  const { data: selectedPayment } = useQuery({
    queryKey: ["AdvancePaymnets", selectedOfficerId, selectedPaymentId],
    queryFn: async () => {
      if (selectedOfficerId && selectedPaymentId) {
        const response = await axiosInstance.get(
          `/approvedAdvancePayment/${selectedOfficerId}/${selectedPaymentId}/${year}/${monthString}`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      }
      return null;
    },
    enabled: !!selectedOfficerId, // Only fetch data if selectedCustomerId is truthy
  });

  //download approved advance payments
  const downloadMutation = useMutation({
    mutationFn: async (downloadData) => {
      const response = await axiosInstance.post(
        `/advancePayment/download`,
        downloadData,
      );

      // Assuming response.data.pdf contains the base64 string of the PDF
      const pdfBase64 = response.data.pdf;
      return pdfBase64;
    },
    onSuccess: async (pdfBase64) => {
      // Create a blob from the base64 PDF string
      const byteCharacters = atob(pdfBase64); // Decode base64 string
      const byteNumbers = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteNumbers], { type: "application/pdf" });

      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(blob);

      // Open the PDF in a new window and trigger print dialog
      const pdfWindow = window.open(blobUrl);
      (pdfWindow as any).onload = () => {
        (pdfWindow as any).print(); //// Trigger print dialog
      };
    },
  });

  //handle download button click.
  const handleDownloadButtonClick = async (
    officerId: any,
    paymentId: any,
    data: [],
  ) => {
    setSelectedOfficerId(officerId);
    setSelectedPaymentId(paymentId);
    await downloadMutation.mutateAsync(data as any);
  };

  const isDownloadEnabled = (status) => {
    return status === "APPROVED";
  };

  return (
    <>
      <div className="ml-8 mt-9 flex flex-row">
        <AiFillDollarCircle className=" mr-3" size={35} />
        <span className=" text-3xl font-bold text-primary-blue ">
          {" "}
          Advance Payment
        </span>
      </div>
      <div className="mx-8 mt-12 flex flex-col gap-12">
        <div className="mt-12 grid grid-cols-2  gap-4 lg:grid-cols-6 lg:gap-8">
          <span className="p-input-icon-left">
            <i className="pi pi-search right-4" />
            <InputText
              placeholder="SO name or ref No."
              className="w-full"
              value={searchTerms}
              onChange={(e) => setSearchTerms(e.target.value)}
            />
          </span>

          {/* <Dropdown
            value={selectedItem}
            onChange={handleRankChange}
            options={items}
            placeholder="Rank"
            className="w-full"
          /> */}

          <Calendar
            value={date}
            onChange={(e) => setDate(e.value as any)}
            showIcon
            dateFormat="mm/yy"
            view="month"
            placeholder="Month"
            pt={{
              dropdownButton: {
                root: {
                  className:
                    "bg-white !border-gray-300 border-l-0 !rounded-l-none !pl-[14px] !pr-[7px] !text-gray-900 !text-2xl",
                },
              },
            }}
          />

          <Button
            className=" ml-8 "
            onClick={() => {
              setisOpenAddModal(true);
            }}
          >
            Add Advance
          </Button>

          {/* <Button
            className=" ml-8 "
            onClick={() => {
              // setModalState(modalStates.ADD);
              // setisOpenAddModal(true);
               
            }}
          >
            Print Advance Summary
          </Button> */}

          <Button onClick={handleClearFilters} className=" w-fit px-8">
            Clear Filters
          </Button>
        </div>
        <div className="max-h-[60vh] overflow-y-auto">
          <table className="w-full min-w-max max-w-[100vw] rounded-[2px] text-left text-sm md:text-base">
            <thead className="sticky top-0 z-10">
              <tr>
                {tableHeaders.map((head) => (
                  <th
                    key={head}
                    className="w-28 break-words bg-white p-4 text-primary-text"
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredPayments &&
                filteredPayments?.map((payment) => (
                  <>
                    <tr
                      className="cursor-pointer border-b border-gray-200 py-10 hover:bg-gray-100"
                      onClick={() => toggleDescription(payment.refNo)}
                    >
                      <td className="flex flex-row items-center px-4 py-4">
                        {payment.refNo}
                      </td>
                      <td className="px-4 py-4">{payment.name}</td>
                      <td className="px-4 py-4">{payment.totalDayShifts}</td>
                      <td className="px-4 py-4">{payment.totalNightShifts}</td>
                      <td className="px-4 py-4">
                        {payment.totalDayShifts + payment.totalNightShifts}
                      </td>
                      <td className="p-4">
                        <div className="flex flex-row items-center space-x-8">
                          <h4 className=" font-semibold">
                            LKR {payment.totalPayment?.toFixed(2)}
                          </h4>
                          <div
                            id="user1Toggle"
                            className="inline-flex items-center rounded-lg border bg-gray-100 px-4 py-4 text-center text-white"
                          >
                            <svg
                              className="h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"></path>
                            </svg>
                          </div>
                        </div>
                      </td>
                    </tr>
                    {expandedRow === payment.refNo &&
                      payment.paymentDetails && (
                        <>
                          <tr
                            id="Description"
                            className="border-t border-gray-200 px-4 py-4"
                          >
                            <td colSpan={6} className="p-8">
                              <div className="max-h-[40vh] overflow-y-auto">
                                <table className="w-full min-w-max max-w-[100vw] rounded-[2px] text-left text-sm md:text-base">
                                  <thead className="sticky top-0 z-10 bg-white">
                                    <th className=" p-4">Date</th>
                                    <th className="p-4">Amount</th>
                                    <th className="p-4">PV Number</th>
                                    <th className="p-4">Payment Method</th>
                                    <th className="p-4">Reference</th>
                                    <th className="p-4">Status</th>
                                    <th className="p-4">Payroll Processed</th>
                                    <th className="p-4">Action</th>
                                  </thead>
                                  <tbody>
                                    {payment.paymentDetails?.map((pay) => (
                                      <tr className="border-t border-gray-200 px-4 py-4">
                                        <td className=" p-4">
                                          {" "}
                                          {new Date(pay.date)
                                            .toLocaleDateString("en-CA", {
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                            })
                                            .replace(/-/g, " ")}
                                        </td>
                                        <td className=" p-4">
                                          LKR {pay.payment?.toFixed(2)}
                                        </td>
                                        <td className=" p-4">{pay.pvNumber}</td>
                                        <td className=" p-4">
                                          {pay.payMethod}
                                        </td>
                                        <td className=" p-4">
                                          {pay.payMethod === "Visiting Officer"
                                            ? pay.voName
                                            : pay.payMethod === "Bank Transfer"
                                              ? pay.bankTransferNo
                                              : "N/A"}
                                        </td>
                                        <td className=" p-4">
                                          {pay.status === "PENDING" ? (
                                            <>
                                              <div className="flex flex-row space-x-2">
                                                <Button
                                                  value={"APPROVED"}
                                                  onClick={() => {
                                                    mutation.mutateAsync({
                                                      officerId:
                                                        payment.officerId,
                                                      paymentId: pay.paymentId,
                                                      status: "APPROVED",
                                                    });
                                                  }}
                                                >
                                                  Approve
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    mutation.mutateAsync({
                                                      officerId:
                                                        payment.officerId,
                                                      paymentId: pay.paymentId,
                                                      status: "REJECTED",
                                                    });
                                                  }}
                                                >
                                                  Reject
                                                </Button>
                                              </div>
                                            </>
                                          ) : (
                                            <>{pay.status}</>
                                          )}
                                        </td>
                                        <td className=" p-4">
                                          <div className="flex items-center space-x-3">
                                            <DotIcon
                                              className={`h-3 w-3 ${
                                                pay.isProcessed === true
                                                  ? "text-[#36A900]"
                                                  : pay.isProcessed === false
                                                    ? "text-[#701616]"
                                                    : "text-white"
                                              }`}
                                            />
                                            {pay.isProcessed !== null && ( // Check if activeStatus is not null
                                              <span>
                                                {pay.isProcessed === true ? (
                                                  <span> Processed </span>
                                                ) : pay.isProcessed ===
                                                  false ? (
                                                  <span> Pending</span>
                                                ) : null}
                                              </span>
                                            )}
                                          </div>
                                        </td>
                                        <td className=" p-4">
                                          <Button
                                            onClick={() => {
                                              handleDownloadButtonClick(
                                                payment.officerId,
                                                pay.paymentId,
                                                selectedPayment,
                                              );
                                            }}
                                            className=" flex text-center"
                                            disabled={
                                              !isDownloadEnabled(pay.status)
                                            }
                                            //loading={downloadMutation.isPending}
                                          >
                                            <TiDownload
                                              //className=" mr-1"
                                              size={20}
                                            />
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                  </>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <AddEditAdvance
        title="Add Advance Payment"
        isOpen={isOpenAddModal}
        onClose={() => {
          setisOpenAddModal(false);
        }}
        year={year}
        monthString={monthString}
      />
    </>
  );
};

export default AdvancePayment;
