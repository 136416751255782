import FormModal from "@/layouts/FormModal";
import { FormLabel } from "@/layouts/Label";
import { TInventory } from "@/models/inventory";
import { modalStates } from "@/models/modelStates";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";

interface AddEditInventoryProps {
  //onSave?: (formData: Inventory) => Promise<void>; to commmit commented
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading?: boolean;
  inventory?: TInventory; // Data for pre-filling the form in edit mode
  isEdit?: boolean; // Flag to indicate data loading for edit mode
}

const tableHeaders = ["Varient Type", "Unit Cost", "Quantity", "Actions"];

const AddEditInventory: React.FC<AddEditInventoryProps> = ({
  isOpen,
  onClose,
  title,
  inventory,
  isEdit,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    //formState: { /*errors*/ }
  } = useForm<TInventory>();

  // const [showEditableRow, setShowEditableRow] = useState(false);
  //   const [isOpenDeleteModal, setisOpenDeleteModal] = useState(false);
  const [editableRows, setEditableRows] = useState({});

  const [, setModalState] = useState(modalStates.ADD);

  useEffect(() => {
    if (isOpen) {
      reset({
        name: "",
        description: "",
        itemVariants: [],
      });
    }

    if (inventory && isEdit) {
      reset({
        name: inventory.name,
        description: inventory.description,
        itemVariants: inventory.itemVariants,
      });
    } else {
      reset();
    }
  }, [inventory, reset, isOpen, isEdit]);

  //using useFeild Array
  const {
    fields: inventoryFields,
    append: inventoryAppend,
    remove: inventoryRemove,
  } = useFieldArray({
    control,
    name: "itemVariants",
  });

  const addNewInventoryRow = () => {
    const newIndex = inventoryFields.length;
    inventoryAppend({
      variantType: "",
      unitCost: 1,
      quantity: 1,
    });
    setEditableRows((prev) => ({
      ...prev,
      [newIndex]: true,
    }));
  };

  const toggleEditableRow = (index) => {
    setEditableRows((prev) => ({
      ...prev,
      [index]: false,
    }));
  };

  const toggleCurrentRow = (index) => {
    setEditableRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const inventoryRemoveRow = (index) => {
    inventoryRemove(index);

    setEditableRows((prev) => {
      const updated = { ...prev };
      delete updated[index];
      Object.keys(updated).forEach((key) => {
        const numKey = parseInt(key);
        if (numKey > index) {
          updated[numKey - 1] = updated[numKey];
          delete updated[numKey];
        }
      });
      return updated;
    });
  };

  //Get te query client instance
  const queryClient = useQueryClient();

  //adding new inventory item
  const mutation = useMutation({
    mutationFn: async (inventory) => {
      const response = await axiosInstance.post("/inventory", inventory);
      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of items after adding a new inventory item
      await queryClient.invalidateQueries("InventoryItems" as any);
      toast.success("Inventory Item Added Succesfully");
    },
    onError: async () => {
      toast.error("Could not add inventory item, Please try again");
    },
  });

  //updating the staff member
  const updateMutation = useMutation({
    mutationFn: ({ itemId, inventory }: any) => {
      return axiosInstance.patch(`/inventory/${itemId}`, inventory);
    },
    onSuccess: async () => {
      //Refetch the list of items after adding a new inventory item
      await queryClient.invalidateQueries("InventoryItems" as any);
      toast.success("Inventory Item Updated Succesfully");
    },
    onError: async () => {
      toast.error("Could not update inventory item, Please try again");
    },
  });

  const onSubmit: SubmitHandler<TInventory> = async (formData) => {
    // Perform add and edit based on mode
    if (isEdit && inventory) {
      await updateMutation.mutateAsync({
        itemId: inventory.id!,
        inventory: formData,
      });
    } else {
      await mutation.mutateAsync(formData as any);
    }
    reset();
    onClose();
  };

  return (
    <>
      <FormModal
        isOpen={isOpen}
        title={title}
        onClose={onClose}
        onSave={handleSubmit(onSubmit)}
        isLoading={mutation.isPending || updateMutation.isPending}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {/** {isDataLoading && (
          <div>
            <SpinnerOverlay />
          </div>
        )}}*/}
          <div className="flex flex-col space-y-10">
            <div className=" text-lg font-semibold text-primary-text">
              Item Details
            </div>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
              <div>
                <FormLabel label="Item Name" required/>
                <InputText
                  {...register("name", {
                    required: "This field is required.",
                  })}
                  className="!bg-inputBg"
                  // placeholder="SO Name"
                />
              </div>
              <div>
                <FormLabel label="Description" required/>
                <InputText
                  {...register("description", {
                    required: "This field is required.",
                  })}
                  className="!bg-inputBg"
                  // placeholder="SO Name"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
              <div className=" text-lg font-semibold text-primary-text">
                Item Varient Details
              </div>
              <div>
                <Button
                  onClick={() => {
                    addNewInventoryRow();
                    setModalState(modalStates.ADD); // Set modal state to ADD
                  }}
                  className="w-fit px-8 lg:ml-4"
                  type="button"
                >
                  Add Varient Item
                </Button>
              </div>
            </div>
            <div className="flex flex-col  pt-8">
              <table>
                <thead>
                  <tr>
                    {tableHeaders.map((head) => (
                      <th
                        key={head}
                        className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-start text-sm font-semibold text-primary-text"
                      >
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {inventoryFields.map((inventory, index) => (
                    <tr key={index}>
                      {editableRows[index] ? (
                        <>
                          <td className="p-4">
                            <InputText
                              {...register(
                                `itemVariants.${index}.variantType`,
                                {
                                  required: "This field is required.",
                                },
                              )}
                              className="bg-inputBg"
                              defaultValue={inventory.variantType}
                            />
                          </td>
                          <td className="p-4">
                            <Controller
                              control={control}
                              name={`itemVariants.${index}.unitCost`}
                              render={({ field }) => (
                                <InputNumber
                                  value={field.value as any}
                                  onValueChange={(e) => field.onChange(e.value)}
                                  useGrouping={true}
                                  minFractionDigits={2}
                                  defaultValue={inventory.unitCost}
                                />
                              )}
                            />
                          </td>
                          <td className="p-4">
                            <Controller
                              control={control}
                              name={`itemVariants.${index}.quantity`}
                              render={({ field }) => (
                                <InputNumber
                                  value={field.value as any}
                                  onValueChange={(e) => field.onChange(e.value)}
                                  min={0}
                                  showButtons
                                  defaultValue={inventory.quantity}
                                />
                              )}
                            />
                          </td>

                          <td className="p-4">
                            <span className="flex flex-row space-x-3">
                              <Button
                                icon="pi pi-check"
                                className="w-3 border-none bg-white !text-primary-blue shadow-none"
                                type="button"
                                onClick={() => {
                                  toggleEditableRow(index);
                                  addNewInventoryRow();
                                }}
                              />
                              <Button
                                icon="pi pi-times"
                                className="w-3 border-none bg-white !text-primary-blue shadow-none"
                                type="button"
                                onClick={() => {
                                  inventoryRemoveRow(index);
                                }}
                              />
                            </span>
                          </td>
                        </>
                      ) : (
                        <>
                          <td className=" p-4">{inventory.variantType}</td>
                          <td className=" p-4">{inventory.unitCost.toFixed(2)}</td>
                          <td className=" p-4">{inventory.quantity}</td>
                          <td className=" p-4">
                            <span className="flex flex-row space-x-3">
                              <Button
                                icon="pi pi-pencil"
                                className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                                type="button"
                                onClick={() => {
                                  toggleCurrentRow(index);
                                }}
                              />
                              <Button
                                icon="pi pi-trash"
                                className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                                type="button"
                                onClick={() => {
                                  inventoryRemoveRow(index);
                                }}
                              />
                            </span>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </FormModal>
    </>
  );
};

export default AddEditInventory;
