import { Months } from "@/constants/common";
import ViewModal from "@/layouts/ViewModal";
import axiosInstance from "@/utils/apiUtil";
import { useQuery } from "@tanstack/react-query";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";

interface ViewCurrentPayrollProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading?: boolean;
  onSave: () => void;
}

const tableHeaders = [
  "Ref No",
  "Name",
  "Total Shifts",
  "Day Shifts",
  "Total Day Shift Payment",
  "Night Shifts",
  "Total Night Shift Payment",
  "Total Advance Payments"
];

const ViewCurrentStatus: React.FC<ViewCurrentPayrollProps> = ({
  isOpen,
  onClose,
  title,
  loading,
  onSave,
}) => {
  const [searchTerms, setSearchTerms] = useState("");
  const [filteredOfficers, setFilteredOfficers] = useState<any>([]);

  const date = new Date();
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const monthString = Months[monthIndex];

  //get current officer status
  const { data: officerDetails } = useQuery({
    queryKey: ["currentStatus"],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/payroll/officers/current/${year}/${monthString}`,
      );
      if (!response) {
        throw new Error("Network response was not ok");
      }

      return response.data;
    },
  });

  useEffect(() => {
    if (officerDetails) {
      setFilteredOfficers(officerDetails);
    }
  }, [officerDetails]);

    //search while typing
    useEffect(() => {
        if (searchTerms) {
    
          const filteredOfficers = searchTerms
            ? officerDetails.filter((item) =>
                item.name.toLowerCase().includes(searchTerms.toLowerCase()) || item.refNo.toLowerCase().includes(searchTerms.toLowerCase()) ,
              )
            : officerDetails;
    
          setFilteredOfficers(filteredOfficers);
        } else {
          setFilteredOfficers(officerDetails);
        }
      }, [searchTerms, officerDetails]);

  return (
    <ViewModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      onSave={onSave}
      isLoading={loading}
      extraLarge
    >
      <>
        <div className=" mb-4 grid grid-cols-2  gap-4 lg:grid-cols-2 lg:gap-8">
          <span className="p-input-icon-left">
            <i className="pi pi-search right-4" />
            <InputText
              placeholder="SO name or Ref No."
              className="w-full"
              value={searchTerms}
              onChange={(e) => setSearchTerms(e.target.value)}
            />
          </span>
        </div>
        <div className="max-h-[60vh] overflow-y-auto">
          <table className="w-full min-w-max max-w-full rounded-[2px] text-left text-sm md:text-base">
            <thead className="sticky top-0 z-10">
              <tr>
                {tableHeaders.map((head) => (
                  <th key={head} className="bg-white p-4 text-primary-text w-28 break-words text-start">
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredOfficers?.map((officer) => (
                <tr className="border-border bg-tableCellBg w-full border border-l-0 border-r-0 text-sm hover:bg-white md:text-base">
                  <td className="p-4">{officer.refNo}</td>
                  <td className="p-4">{officer.name}</td>
                  <td className="p-4">{officer.totalShifts}</td>
                  <td className="p-4">{officer.totalDayShifts}</td>
                  <td className="p-4">{officer.dayShiftPayment?.toFixed(2)}</td>
                  <td className="p-4">{officer.totalNightShifts}</td>
                  <td className="p-4">{officer.nightShiftPayment?.toFixed(2)}</td>
                  <td className="p-4">{officer.salaryAdvancement?.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    </ViewModal>
  );
};

export default ViewCurrentStatus;
